
























































































import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";
import UploadImage from "@/components/UploadImage.vue";
import Events from "@/utils/Events";
import Extras from "@/utils/Extras";
import { IUploaded } from "@/utils/Interfaces";
import UploadVideo from "@/components/UploadVideo.vue";

@Component({
  components: { UploadVideo, UploadImage },
})
export default class Upload extends Vue {
  @Prop({ default: false }) multiple: boolean;
  @Prop({ required: true }) path: string; // no start & end slash
  @Prop({ default: false }) isImage: boolean;
  @Prop({ default: false }) isVideo: boolean;
  @Prop({ default: null }) extras: any; // extra in upload image/video
  @Prop({ default: [] }) tr: string[];
  @Prop() beforeUpload;

  private selectFiles: File[] = [];
  private files: File[] = [];
  private drag = false;

  // emit uploaded
  @Emit()
  uploaded(data: IUploaded): IUploaded {
    return data;
  }

  // accept files text
  get acceptText(): string {
    if (this.isImage) return "(.jpg / .jpeg / .png)";
    else if (this.isVideo) return "(.mp4)";
    else return "";
  }

  // accept extensions
  get acceptExtensions(): string {
    if (this.isImage) return ".jpg,.jpeg,.png,.webp";
    else if (this.isVideo) return ".mp4";
    else return "";
  }

  // drag over
  handleDragOver() {
    this.drag = true;
  }

  // drag leave
  handleDragLeave() {
    this.drag = false;
  }

  // handle dropped file
  handleDrop(files: DataTransferItem[]) {
    this.drag = false;
    // in case of single item
    if (!this.multiple) {
      // if more than one file selected
      if (files.length > 1) Events.error("can't add multiple files");
      // already file is selected
      else if (this.files.length > 0) Events.error("can't add more files");
      // add file
      else {
        // get file
        const file = files[0].getAsFile();
        // validate if isImage
        if (this.isImage) {
          if (this.checkImageExt(file.name)) {
            // add file to array
            this.files.push(file);
          }
          // not image
          else Events.error("not an image - " + file.name);
        }
        // validate if isVideo
        if (this.isVideo) {
          if (this.checkVideoExt(file.name)) {
            // add file to array
            this.files.push(file);
          }
          // not video
          else Events.error("not a video - " + file.name);
        }
      }
    }

    // multiple items
    else
      for (let el of files) {
        const file = el.getAsFile();
        const match = this.files.filter((el) => el.name === file.name);
        if (match.length > 0)
          Events.error(
            `file with same name already uploading - '${file.name}'`
          );
        else {
          // validate if isImage
          if (this.isImage) {
            if (this.checkImageExt(file.name)) {
              // add file to array
              this.files.push(file);
            }
            // not image
            else Events.error("not an image - " + file.name);
          }
          // validate if isVideo
          else if (this.isVideo) {
            if (this.checkVideoExt(file.name)) {
              // add file to array
              this.files.push(file);
            }
            // not video
            else Events.error("not a video (mp4) - " + file.name);
          }
        }
      }
  }

  // handle selected file
  handleSelect(files: File[]) {
    this.drag = false;
    // in case of single item
    if (!this.multiple) {
      // if more than one file selected
      if (files.length > 1) Events.error("can't add multiple files");
      // already file is selected
      else if (this.files.length > 0) Events.error("can't add more files");
      // add file
      else {
        // get file
        const file = files[0];
        // validate if isImage
        if (this.isImage) {
          if (this.checkImageExt(file.name)) {
            // add file to array
            this.files.push(file);
          }
          // not image
          else Events.error("not an image - " + file.name);
        }
        // validate if isVideo
        else if (this.isVideo) {
          if (this.checkVideoExt(file.name)) {
            // add file to array
            this.files.push(file);
          }
          // not image
          else Events.error("not a video (mp4) - " + file.name);
        }
      }
    }

    // multiple items
    else
      for (let el of files) {
        const file = el;
        const match = this.files.filter((el1) => el1.name === file.name);
        if (match.length > 0)
          Events.error(
            `file with same name already uploading - '${file.name}'`
          );
        else {
          // validate if isImage
          if (this.isImage) {
            if (this.checkImageExt(file.name)) {
              // add file to array
              this.files.push(file);
            }
            // not image
            else Events.error("not an image - " + file.name);
          }
          // validate if isImage
          else if (this.isVideo) {
            if (this.checkVideoExt(file.name)) {
              // add file to array
              this.files.push(file);
            }
            // not video
            else Events.error("not a video (mp4) - " + file.name);
          }
        }
      }
  }

  // check image extension
  checkImageExt(name: string): boolean {
    const ext = Extras.ext(name);
    return ext === "jpg" || ext === "jpeg" || ext === "png" || ext === "webp";
  }

  // check video extension
  checkVideoExt(name: string): boolean {
    const ext = Extras.ext(name);
    return ext === "mp4";
  }
}
