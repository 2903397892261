


















































































































import Vue from "vue";
import CardCount from "@/components/CardCount.vue";
import CardCountLarge from "@/components/CardCountLarge.vue";

export default Vue.extend({
  name: "Home",
  components: { CardCountLarge, CardCount },
  data() {
    return {};
  },
});
