/**
 * @info generate functions
 */
import shortid from "shortid";

export default class Generate {
  /**
   * return short id
   */
  static short() {
    return shortid.generate();
  }
}
