










































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { IUploaded } from "@/utils/Interfaces";
import Upload from "@/layout/Upload.vue";
import Global from "@/config/Global";
import Events from "@/utils/Events";
import Time from "@/utils/Time";

@Component({
  components: { Upload },
})
export default class NewDual extends Vue {
  private loading = false;
  private wallType: string = null;
  private lockscreen: IUploaded = null;
  private homescreen: IUploaded = null;
  private coins = 0;

  // on component create
  created() {
    this.$store.state.sidebar = "";
  }

  // show upload
  get showUpload() {
    return this.wallType !== null;
  }

  // get coin count
  get coinCount() {
    return this.coins + " coins";
  }

  // reload page
  reload() {
    this.$router.go(0);
  }

  // uploaded homescreen & submit
  uploadedHomescreen(uploaded: IUploaded) {
    this.homescreen = uploaded;
    this.loading = true;

    // generate new key for list
    const updates = {};
    const newWallRef = Global.database.push("content/dual");

    // add new dual wallpaper
    updates["content/dual/" + newWallRef.key] = {
      id: newWallRef.key,
      createdAt: Time.currentUTC(),
      coins: this.coins,
      applies: 0,
      downloads: 0,
      likes: 0,
      views: 0,
      popular: 0,
      premium: this.wallType === "Premium",
      src: [this.lockscreen.updated, this.homescreen.updated],
    };

    // if premium increase count
    if (this.wallType === "Premium")
      updates["content/counter/wallpaper/premium/dual"] =
        Global.database.increment(1);

    // increase total count
    updates["content/counter/total/wallpapers"] = Global.database.increment(1);

    // increase dual count
    updates["content/counter/wallpaper/type/dual"] =
      Global.database.increment(1);

    // send updates
    Global.database
      .update(updates)
      .then(() => {
        Events.success(`Wallpaper added to database`);
      })
      .catch((err) => {
        console.log(err);
        Events.error(`Failed to insert wallpaper to database`);
      })
      .finally(() => {
        this.loading = false;
        this.$router.push({ name: "dual" });
      });
  }

  // uploaded lockscreen
  uploadLockscreen(uploaded: IUploaded) {
    this.lockscreen = uploaded;
  }
}
