import NewGeneric from "@/pages/NewGeneric.vue";
import NewCategory from "@/pages/NewCategory.vue";
import NewVideo from "@/pages/NewVideo.vue";
import NewDual from "@/pages/NewDual.vue";
import NewParallax from "@/pages/NewParallax.vue";

/**
 * @info all create routes
 */

export default [
  {
    path: "/new/generic",
    name: "newGeneric",
    component: NewGeneric,
  },
  {
    path: "/new/category",
    name: "newCategory",
    component: NewCategory,
  },
  {
    path: "/new/video",
    name: "newVideo",
    component: NewVideo,
  },
  {
    path: "/new/dual",
    name: "newDual",
    component: NewDual,
  },
  {
    path: "/new/parallax",
    name: "newParallax",
    component: NewParallax,
  },
];
