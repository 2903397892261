import PreviewGeneric from "@/pages/PreviewGeneral.vue";
import PreviewDual from "@/pages/PreviewDual.vue";
import PreviewParallax from "@/pages/PreviewParallax.vue";
import PreviewVideo from "@/pages/PreviewVideo.vue";
import PreviewCategory from "@/pages/PreviewCategory.vue";

/**
 * @info all routes for preview
 */

export default [
  // generic
  {
    path: "/preview/generic/:id",
    name: "previewGeneric",
    component: PreviewGeneric,
  },

  // dual
  {
    path: "/preview/dual/:id",
    name: "previewDual",
    component: PreviewDual,
  },

  // parallax
  {
    path: "/preview/parallax/:id",
    name: "previewParallax",
    component: PreviewParallax,
  },

  // video
  {
    path: "/preview/video/:id",
    name: "previewVideo",
    component: PreviewVideo,
  },

  // category
  {
    path: "/preview/category/:id",
    name: "previewCategory",
    component: PreviewCategory,
  },
];
