











import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";
import Generate from "@/utils/Generate";
import Extras from "@/utils/Extras";
import Global from "@/config/Global";
import { IUploaded } from "@/utils/Interfaces";

@Component
export default class UploadImage extends Vue {
  @Prop() file: File;
  @Prop({ required: true }) path: string;
  @Prop({ default: false }) multiple: boolean;
  @Prop({ default: null }) extras: any;
  @Prop({ default: [] }) tr: string[];

  private progress = 0;
  private name: string;
  private indeterminate = false;

  // emit uploaded
  @Emit()
  uploaded(): IUploaded {
    return {
      original: this.file.name,
      updated: this.name,
      extras: this.extras,
    };
  }

  // on created
  created() {
    this.upload();
  }

  // get url of image
  get url() {
    return URL.createObjectURL(this.file);
  }

  // start upload
  upload() {
    // create path with shortid
    this.name = `${Generate.short()}.${Extras.ext(this.file.name)}`;
    const path = `${this.path}/${this.name}`;

    // start s3 upload
    Global.s3
      .upload(this.file, path, (progress) => {
        if (progress === 100) this.progress = 99;
        this.progress = progress;
      })
      .then(async () => {
        // check if tr is present
        if (this.tr.length > 0) {
          // set progress indeterminate
          this.indeterminate = true;
          // start transcoding
          await Global.imagekit.transcodeAndSave(
            this.tr,
            this.name,
            this.path,
            (p) => {
              // callback
              this.indeterminate = false;
              this.progress = p;
            }
          );
        }
        // set complete progress
        this.progress = 100;
        // call uploaded
        this.uploaded();
      })
      .catch((err) => {
        console.log(err);
        this.indeterminate = false;
        this.progress = 0;
      });
  }
}
