/**
 * @info formatting
 */

export default class Format {
  /**
   * format number to SI
   * @param num
   * @param digits
   */
  static formatNum(num, digits = 1): string {
    if (num >= 1000) {
      if (num > 9950) digits = 0;
      const units = ["k", "M", "G", "T", "P", "E", "Z", "Y"];
      const floor = Math.floor(Math.abs(num).toString().length / 3);
      const value = +(num / Math.pow(1000, floor));
      return value.toFixed(value > 1 ? digits : 2) + units[floor - 1];
    } else return num.toString();
  }
}
