import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Vuesax from "vuesax";
import InlineSvg from "vue-inline-svg";
import VueGlow from "vue-glow";
import "vuesax/dist/vuesax.css"; //Vuesax styles
import VueCookies from "vue-cookies";

import "@/scss/_global.scss";
import "@/scss/_font.scss";

Vue.component("InlineSvg", InlineSvg);
Vue.component("VueGlow", VueGlow);
Vue.use(Vuesax);
Vue.use(VueCookies);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
