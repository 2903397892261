





















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ICategory, ISnapshot } from "@/utils/Interfaces";

@Component
export default class ViewCategory extends Vue {
  @Prop() category: ISnapshot<ICategory>;

  // open preview
  preview() {
    this.$router.push({
      name: "previewCategory",
      params: { id: this.category.key },
    });
  }
}
