





























































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Global from "@/config/Global";
import Events from "@/utils/Events";
import Time from "@/utils/Time";

@Component
export default class NewCategory extends Vue {
  private name: string = null;
  private color = "#000000";
  private loading = false;

  // on component create
  created() {
    this.$store.state.sidebar = "";
  }

  // reload page
  reload() {
    this.$router.go(0);
  }

  // create new category
  createCategory() {
    this.loading = true;

    Global.firebase
      .permission("category", "add")
      .then(() => {
        // generate new key for list
        const updates = {};
        const newRef = Global.database.push("content/category");

        // add new category
        updates["content/category/" + newRef.key] = {
          id: newRef.key,
          createdAt: Time.currentUTC(),
          name: this.name,
          color: this.color,
          wallpapers: 0,
        };

        // increment counter
        updates["content/counter/total/category"] =
          Global.database.increment(1);

        // send updates
        Global.database
          .update(updates)
          .then(() => {
            Events.success(`Category added to database`);
            this.$router.push({ name: "category" });
            this.name = null;
          })
          .catch((err) => {
            console.log(err);
            Events.error(`Failed to insert category`);
          })
          .finally(() => {
            this.loading = false;
          });
      })
      .catch((err) => {
        Events.error(err.message);
        this.loading = false;
      });
  }

  // get color dot style
  get colorDot() {
    return {
      height: "30px",
      width: "30px",
      "background-color": this.color,
      "border-radius": "50%",
      "border-color": "black",
      "border-width": "1px",
      "border-style": "solid",
    };
  }

  // show submit or not
  get disableSubmit() {
    return !this.name;
  }
}
