












import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";
import { IUploaded } from "@/utils/Interfaces";
import Generate from "@/utils/Generate";
import Extras from "@/utils/Extras";
import Global from "@/config/Global";

@Component
export default class UploadVideo extends Vue {
  @Prop() file: File;
  @Prop({ required: true }) path: string;
  @Prop({ default: false }) multiple: boolean;

  private progress = 0;
  private name: string;

  // emit uploaded
  @Emit()
  uploaded(): IUploaded {
    return {
      original: this.file.name,
      updated: this.name,
      extras: null,
    };
  }

  // on created
  created() {
    this.upload();
  }

  // get url of image
  get url() {
    return URL.createObjectURL(this.file);
  }

  // start upload
  upload() {
    // create path with shortid
    this.name = `${Generate.short()}.${Extras.ext(this.file.name)}`;
    const path = `${this.path}/${this.name}`;

    // start s3 upload
    Global.s3
      .uploadVideo(this.file, path, (progress) => {
        if (progress === 100) this.progress = 99;
        this.progress = progress;
      })
      .then(() => {
        this.progress = 100;
        this.uploaded();
      })
      .catch(() => {
        this.progress = 0;
      });
  }
}
