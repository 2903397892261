import axios, { AxiosResponse } from "axios";
import Global from "@/config/Global";
import Bluebird from "bluebird";
import Regex from "@/utils/Regex";

/**
 * @info imagekit transformations
 */

export default class Imagekit {
  creds: any;

  /**
   * initialize
   */
  async init() {
    this.creds = (await Global.database.getOnce("admin/creds/imagekit")) as any;
  }

  /**
   * fetch image from imagekit
   * @param tr - named transform
   * @param path - image path with name
   */
  private fetchImage(tr: string, path: string): Promise<ArrayBuffer> {
    const url = `${this.creds.host}/${path}?tr=n-${tr}`;

    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          responseType: "arraybuffer",
        })
        .then((result: AxiosResponse<ArrayBuffer>) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * transcode to given transformations & save to s3
   * @param tr - transformations array
   * @param image - base image full path
   * @param path - path to save transformed images
   * @param callback
   */
  transcodeAndSave(
    tr: string[],
    image: string,
    path: string,
    callback: (percent: number) => void
  ): Promise<any> {
    // for each transformation
    return Bluebird.each(tr, (item, index) => {
      // fetch transformed image
      return this.fetchImage(item, `${path}/${image}`).then((data) => {
        // create new name
        const ext = Regex.extension.exec(image)[0];
        const name = `${image.replace(ext, "")}-${item}.webp`;
        // save transformed image in s3
        return Global.s3.uploadBuffer(data, `${path}/${name}`, (p) => {
          callback((p / tr.length) * (index + 1));
        });
      });
    });
  }
}
