import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    sidebar: "home",
    user: false,
    generic: null,
    dual: null,
    parallax: null,
    video: null,
    event: {
      type: "",
      data: "",
    },
    dialog: {
      confirm: false,
    },
    confirm: {
      // unique identifier
      key: "",
      title: "",
      description: "",
      yes: false,
    },
  },
  mutations: {},
  actions: {},
  modules: {},
});
