




























































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { IGeneric } from "@/utils/Interfaces";
import Global from "@/config/Global";
import TooltipText from "@/components/TooltipText.vue";
import Format from "@/utils/Format";
import Events from "@/utils/Events";
import Regex from "@/utils/Regex";
import silent from "@/utils/silent";

@Component({
  components: { TooltipText },
})
export default class PreviewDual extends Vue {
  private loading = false;
  private wallpaper: IGeneric;
  private id: string;
  private format = Format;

  // on create
  created() {
    this.$store.state.sidebar = "";

    this.wallpaper = this.$store.state.dual;
    this.id = this.$route.params.id;
  }

  // src of homescreen image
  get homescreen() {
    // get name without extension
    const ext = Regex.extension.exec(this.wallpaper.src[1])[0];
    const name = this.wallpaper.src[1].replace(ext, "");

    return {
      thumb: `${Global.s3.cdn}/images/dual/homescreen/${name}-thumb_small.webp`,
      full: `${Global.s3.cdn}/images/dual/homescreen/${this.wallpaper.src[1]}`,
    };
  }

  // src of lockscreen image
  get lockscreen() {
    // get name without extension
    const ext = Regex.extension.exec(this.wallpaper.src[0])[0];
    const name = this.wallpaper.src[0].replace(ext, "");

    return {
      thumb: `${Global.s3.cdn}/images/dual/lockscreen/${name}-thumb_small.webp`,
      full: `${Global.s3.cdn}/images/dual/lockscreen/${this.wallpaper.src[0]}`,
    };
  }

  // delete handling
  async deleteWall() {
    this.loading = true;

    // check if user has permission
    Global.firebase
      .permission("dual","delete")
      .then(() => {
        // generate new key for list
        const updates = {};

        // delete dual wallpaper
        updates["content/dual/" + this.id] = null;

        // if premium decrease count
        if (this.wallpaper.premium)
          updates["content/counter/wallpaper/dual/generic"] =
            Global.database.decrement(1);

        // decrease total count
        updates["content/counter/total/wallpapers"] =
          Global.database.decrement(1);

        // decrease dual count
        updates["content/counter/wallpaper/type/dual"] =
          Global.database.decrement(1);

        // send updates
        Global.database
          .update(updates)
          .then(() => {
            Events.success("Image Deleted");
            this.$router.push({ name: "dual" });
            this.deleteS3();
          })
          .catch((err) => {
            console.log(err);
            Events.error(`Failed to delete image`);
          })
          .finally(() => {
            this.loading = false;
          });
      })
      .catch((err) => {
        Events.error(err.message);
        this.loading = false;
      });
  }

  // delete from s3
  deleteS3() {
    // get name without extension
    const extL = Regex.extension.exec(this.wallpaper.src[0])[0];
    const extH = Regex.extension.exec(this.wallpaper.src[1])[0];
    const nameL = this.wallpaper.src[0].replace(extL, "");
    const nameH = this.wallpaper.src[1].replace(extH, "");

    // silently delete all
    silent(
      Global.s3.deleteFile(`images/dual/lockscreen/${this.wallpaper.src[0]}`)
    );
    silent(
      Global.s3.deleteFile(`images/dual/lockscreen/${nameL}-thumb_small.webp`)
    );
    silent(
      Global.s3.deleteFile(`images/dual/lockscreen/${nameL}-thumb_large.webp`)
    );
    silent(Global.s3.deleteFile(`images/dual/lockscreen/${nameL}-large.webp`));

    silent(
      Global.s3.deleteFile(`images/dual/homescreen/${this.wallpaper.src[1]}`)
    );
    silent(
      Global.s3.deleteFile(`images/dual/homescreen/${nameH}-thumb_small.webp`)
    );
    silent(
      Global.s3.deleteFile(`images/dual/homescreen/${nameH}-thumb_large.webp`)
    );
    silent(Global.s3.deleteFile(`images/dual/lockscreen/${nameH}-large.webp`));
  }
}
