

























import Global from "@/config/Global";

export default {
  name: "CardCount",
  props: {
    title: String,
    icon: String,
    path: String,
  },
  data() {
    return {
      value: 0,
    };
  },
  created() {
    Global.database.getLive(this.path, (value: Number) => {
      this.value = value;
    });
  },
};
