import Generic from "@/pages/GenericWalls.vue";
import Video from "@/pages/VideoWalls.vue";
import Dual from "@/pages/DualWalls.vue";
import Parallax from "@/pages/ParallaxWalls.vue";
import Category from "@/pages/Categories.vue";

/**
 * @info all routes with list of items
 */

export default [
  {
    path: "/generic",
    name: "generic",
    component: Generic,
  },
  {
    path: "/video",
    name: "video",
    component: Video,
  },
  {
    path: "/dual",
    name: "dual",
    component: Dual,
  },
  {
    path: "/parallax",
    name: "parallax",
    component: Parallax,
  },
  {
    path: "/category",
    name: "category",
    component: Category,
  },
];
