
























































































import vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { IGeneric } from "@/utils/Interfaces";
import Global from "@/config/Global";
import Format from "@/utils/Format";
import TooltipText from "@/components/TooltipText.vue";
import Regex from "@/utils/Regex";

@Component({
  components: { TooltipText },
})
export default class ViewVideoWall extends vue {
  @Prop() wallpaper: IGeneric;
  @Prop() id: string;
  reveal: Boolean = false;
  style: object = {};
  format = Format;

  // on create
  created() {
    // get name without extension
    const ext = Regex.extension.exec(this.wallpaper.src[0])[0];
    const name = this.wallpaper.src[0].replace(ext, "");

    // init style
    this.style = {
      "border-radius": "16px",
      "background-image": `url(${Global.s3.url}/images/video/thumbnail/${name}-thumb_large.webp)`,
      "background-size": "cover",
    };
  }

  // preview
  preview() {
    this.$store.state.video = this.wallpaper;
    this.$router.push({ name: "previewVideo", params: { id: this.id } });
  }
}
