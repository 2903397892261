

























































export default {
  name: "Navbar",
  methods: {
    goTo(name) {
      this.$router.push({ name });
    },
  },
};
