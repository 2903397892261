import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#1C2E4F",
        secondary: "#195BFF",
        accent: "#FCDBB6",
        bg: "#FCFBFE",
        grey1: "#F0F3F4",
        grey2: "#A7B8BF",
      },
    },
  },
});
