/**
 * @info time handling
 */
import moment from "moment";

export default class Time {
  /**
   * return current date time (UTC)
   * in platform generic format
   */
  public static currentUTC() {
    return moment().utc().format();
  }
}
