




















































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { IUploaded } from "@/utils/Interfaces";
import Upload from "@/layout/Upload.vue";
import Global from "@/config/Global";
import Events from "@/utils/Events";
import Generate from "@/utils/Generate";
import Time from "@/utils/Time";

@Component({
  components: { Upload },
})
export default class NewParallax extends Vue {
  private loading = false;
  private wallType: string = null;
  private noOfLayers: number = null;
  private thumbnail: IUploaded = null;
  private layers: IUploaded[] = [];
  private coins = 0;

  private disabled = false;

  // on component create
  created() {
    this.$store.state.sidebar = "";
  }

  // coin count
  get coinCount() {
    return this.coins + " coins";
  }

  // upload path
  get uploadPath() {
    return `images/parallax/${Generate.short()}`;
  }

  // show upload
  get showUpload() {
    const show = this.wallType !== null && this.noOfLayers !== null;
    if (show) this.disabled = true;
    return show;
  }

  // disable submit
  get disableSubmit() {
    return !(this.layers.length === this.noOfLayers && this.thumbnail !== null);
  }

  // reload page
  reload() {
    this.$router.go(0);
  }

  // uploaded layer
  uploadedLayer(uploaded: IUploaded) {
    this.layers.push(uploaded);
  }

  // uploaded lockscreen
  uploadThumbnail(uploaded: IUploaded) {
    this.thumbnail = uploaded;
  }

  // submit
  submit() {
    this.loading = true;

    // generate new key for list
    const updates = {};
    const newWallRef = Global.database.push("content/parallax");

    // map layers to src
    const src = {};
    // get unique path
    const path = this.uploadPath.replace("images/parallax/", "");

    // add thumbnail
    src[0] = `${path}/${this.thumbnail.updated}`;
    // add layers
    this.layers.forEach((layer) => {
      src[Number(layer.extras)] = `${path}/${layer.updated}`;
    });

    // add new dual wallpaper
    updates["content/parallax/" + newWallRef.key] = {
      id: newWallRef.key,
      createdAt: Time.currentUTC(),
      coins: this.coins,
      applies: 0,
      downloads: 0,
      likes: 0,
      views: 0,
      popular: 0,
      premium: this.wallType === "Premium",
      src,
      layers: this.noOfLayers,
    };

    // if premium increase count
    if (this.wallType === "Premium")
      updates["content/counter/wallpaper/premium/parallax"] =
        Global.database.increment(1);

    // increase total count
    updates["content/counter/total/wallpapers"] = Global.database.increment(1);

    // increase dual count
    updates["content/counter/wallpaper/type/parallax"] =
      Global.database.increment(1);

    // send updates
    Global.database
      .update(updates)
      .then(() => {
        Events.success(`Wallpaper added to database`);
      })
      .catch((err) => {
        console.log(err);
        Events.error(`Failed to insert wallpaper to database`);
      })
      .finally(() => {
        this.loading = false;
        this.$router.push({ name: "parallax" });
      });
  }
}
