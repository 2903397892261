













































































































import vue from "vue";
import { Prop, Component } from "vue-property-decorator";
import { ICategory, IGeneric } from "@/utils/Interfaces";
import Global from "@/config/Global";
import Format from "@/utils/Format";
import TooltipText from "@/components/TooltipText.vue";
import Regex from "@/utils/Regex";
import moment from "moment";
@Component({
  components: { TooltipText },
})
export default class HelloWorld extends vue {
  @Prop() wallpaper: IGeneric;
  @Prop() id: string;
  reveal: Boolean = false;
  categories: ICategory[] = [];
  style: object = {};
  format = Format;
  tags = [];

  // on create
  created() {
    // get name without extension
    const ext = Regex.extension.exec(this.wallpaper.src[0])[0];
    const name = this.wallpaper.src[0].replace(ext, "");
    // init style
    this.style = {
      "border-radius": "16px",
      "background-image": `url(${Global.s3.url}/images/generic/${name}-thumb_large.webp)`,
      "background-size": "cover",
    };

    // get category name
    Object.keys(this.wallpaper.categories).forEach((el) => {
      Global.database.getOnce(`content/category/${el}`).then((data) => {
        this.categories.push(data);
      });
    });

    // parse tags
    this.tags = Object.keys(this.wallpaper.tags);
  }

  // get all category names
  get categoryNames(): string {
    return this.categories.map((el) => el.name).join(",");
  }

  // open preview
  preview() {
    this.$store.state.generic = this.wallpaper;
    this.$router.push({ name: "previewGeneric", params: { id: this.id } });
  }

  // local date
  localDate(date) {
    return moment(date).local().format("YYYY-MM-DD HH:mm:ss");
  }
}
