














import Extras from "@/utils/Extras";
import Global from "@/config/Global";

export default {
  name: "CardCountLarge",
  props: ["title", "icon", "color", "path"],
  created() {
    Global.database.getLive(this.path, (value) => {
      this.count = value;
    });
  },
  data() {
    return {
      count: 0,
      darkColor: Extras.shadeColor(this.color, -30),
      style: {
        color: Extras.shadeColor(this.color, -30),
      },
      outline: {
        "border-radius": "20px",
        "border-color": this.color,
        "border-width": "4px",
      },
    };
  },
};
