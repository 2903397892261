
































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { ICategory, IGeneric } from "@/utils/Interfaces";
import Global from "@/config/Global";
import TooltipText from "@/components/TooltipText.vue";
import Format from "@/utils/Format";
import Events from "@/utils/Events";
import silent from "@/utils/silent";
import Regex from "@/utils/Regex";

@Component({
  components: { TooltipText },
})
export default class PreviewVideo extends Vue {
  private loading = false;
  private wallpaper: IGeneric;
  private id: string;
  private categories: ICategory[] = [];
  private format = Format;

  // on create
  created() {
    this.$store.state.sidebar = "";

    this.wallpaper = this.$store.state.video;
    this.id = this.$route.params.id;
  }

  // src of thumbnail
  get thumbnail() {
    // get name without extension
    const ext = Regex.extension.exec(this.wallpaper.src[0])[0];
    const name = this.wallpaper.src[0].replace(ext, "");

    return {
      full: `${Global.s3.cdn}/images/video/thumbnail/${this.wallpaper.src[0]}`,
      thumb: `${Global.s3.cdn}/images/video/thumbnail/${name}-thumb_small.webp`,
      med: `${Global.s3.cdn}/images/video/thumbnail/${name}-thumb_large.webp`,
    };
  }

  // src of video
  get videoSrc() {
    return `${Global.s3.cdn}/images/video/${this.wallpaper.src[1]}`;
  }

  // delete handling
  deleteWall() {
    this.loading = true;

    Global.firebase
      .permission("video", "delete")
      .then(() => {
        // generate new key for list
        const updates = {};

        // delete video
        updates["content/video/" + this.id] = null;

        // if premium decrease count
        if (this.wallpaper.premium)
          updates["content/counter/wallpaper/premium/video"] =
            Global.database.decrement(1);

        // decrease total count
        updates["content/counter/total/wallpapers"] =
          Global.database.decrement(1);

        // decrease generic count
        updates["content/counter/wallpaper/type/video"] =
          Global.database.decrement(1);

        // send updates
        Global.database
          .update(updates)
          .then(() => {
            Events.success("Video Deleted");
            this.$router.push({ name: "video" });
            this.deleteS3();
          })
          .catch((err) => {
            console.log(err);
            Events.error(`Failed to delete video`);
          })
          .finally(() => {
            this.loading = false;
          });
      })
      .catch((err) => {
        Events.error(err.message);
        this.loading = false;
      });
  }

  // delete from s3
  deleteS3() {
    // get name without extension
    const ext = Regex.extension.exec(this.wallpaper.src[0])[0];
    const name = this.wallpaper.src[0].replace(ext, "");

    // silently delete all
    silent(
      Global.s3.deleteFile(`images/video/thumbnail/${this.wallpaper.src[0]}`)
    );
    silent(
      Global.s3.deleteFile(`images/video/thumbnail/${name}-thumb_small.webp`)
    );
    silent(
      Global.s3.deleteFile(`images/video/thumbnail/${name}-thumb_large.webp`)
    );
    silent(Global.s3.deleteFile(`images/video/${this.wallpaper.src[1]}`));
  }
}
