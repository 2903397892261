import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../pages/Home.vue";
import Login from "@/pages/Login.vue";
import routeList from "@/router/routeList";
import routeNew from "@/router/routeNew";
import routePreview from "@/router/routePreview";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  // home
  {
    path: "/",
    name: "home",
    component: Home,
  },

  // login
  {
    path: "/login",
    name: "login",
    component: Login,
  },

  // reference
  ...routeList,
  ...routeNew,
  ...routePreview,
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
