<template>
  <vs-navbar shadow square class="py-1">
    <template #left>
      <!--to give some space-->
      <span class="ml-16 pl-16 h6 ls-2 text-uppercase fb primary--text">
        WallApp
      </span>
    </template>

    <template #right>
      <!--logout-->
      <div class="mr-16">
        <vs-button
          class="py-1 px-4 ls-1-5 fb"
          danger
          @click="logoutDialog = true"
        >
          LOGOUT
        </vs-button>
      </div>

      <!--logout dialog-->
      <dialog-logout :trigger="logoutDialog" @closed="logoutDialog = false" />
    </template>
  </vs-navbar>
</template>

<script>
import DialogLogout from "@/layout/DialogLogout";

export default {
  name: "Appbar",
  components: { DialogLogout },
  data: () => ({
    logoutDialog: false,
    tab: 0,
  }),
};
</script>

<style scoped></style>
