<template>
  <vs-dialog width="100px" blur v-model="trigger" @close="closed">
    <template #header>
      <span class="ff-works fb h4 mt-4">Logout?</span>
    </template>

    <template #footer>
      <v-row>
        <!--logout-->
        <vs-button
          color="danger"
          block
          class="ff-works fb ls-2 mb-6 mx-8"
          :loading="loading"
          @click="logoutCall"
        >
          <v-icon color="white">mdi-power</v-icon>
          <span class="ml-4">YEAP!!</span>
        </vs-button>
      </v-row>
    </template>
  </vs-dialog>
</template>

<script>
import Global from "@/config/Global";
import Events from "@/utils/Events";

export default {
  name: "DialogLogout",
  props: ["trigger"],
  emits: ["closed"],
  data: () => ({
    loading: false,
  }),
  // methods
  methods: {
    // dialog closed
    closed() {
      this.$emit("closed");
    },
    // logout
    logoutCall() {
      this.loading = true;
      Global.firebase
        .logout()
        .then(() => {
          this.$cookies.remove("user");
          this.$store.state.user = false;
          Events.success(
            "Logged Out",
            "successfully logged out, please wait..."
          );
        })
        .catch((err) => {
          Events.error(err.message);
        })
        .finally(() => {
          this.loading = false;
          this.$emit("closed");
        });
    },
  },
};
</script>

<style scoped></style>
