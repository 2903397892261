


















































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { ICategory } from "@/utils/Interfaces";
import Global from "@/config/Global";
import Format from "@/utils/Format";
import Events from "@/utils/Events";

@Component({})
export default class PreviewCategory extends Vue {
  private loading = true;
  private category: ICategory;
  private id: string;
  private format = Format;
  private count = 0;

  // on create
  created() {
    this.$store.state.sidebar = "";

    this.id = this.$route.params.id;
    Global.database
      .getOnce("content/category/" + this.id)
      .then((data) => (this.category = data))
      .catch((err) => Events.error(err.message))
      .finally(() => {
        this.loading = false;
      });
  }

  // style of name
  get style() {
    return { color: this.category.color };
  }
}
