























































import Global from "@/config/Global";
import Events from "@/utils/Events";

export default {
  name: "Login",
  created() {
    if (this.$cookies.isKey("user")) this.$router.push({ name: "home" });
  },
  data() {
    return {
      showPass: false,
      email: null,
      password: null,
      loading: false,
    };
  },
  // computed
  computed: {
    // show login button
    disableLogin() {
      return !this.email && !this.password;
    },
  },
  // methods
  methods: {
    // perform login
    login() {
      this.loading = true;
      // start login process
      Global.firebase
        .login(this.email, this.password)
        .then((data) => {
          Events.success("LOGIN SUCCESS");
          // set cookie
          this.$cookies.set("user", data.user.uid, "1y");
          this.$router.go(0);
        })
        .catch((err) => {
          Events.error(err.toString());
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
