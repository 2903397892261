/**
 * @info
 */
import Regex from "@/utils/Regex";
import axios, { AxiosResponse } from "axios";
import Global from "@/config/Global";
import silent from "@/utils/silent";

export default class Extras {
  /**
   * create shade light/dark of given color
   * @param color
   * @param percent
   */
  static shadeColor(color: string, percent: number) {
    let R: number = parseInt(color.substring(1, 3), 16);
    let G: number = parseInt(color.substring(3, 5), 16);
    let B: number = parseInt(color.substring(5, 7), 16);

    R = parseInt(((R * (100 + Number(percent))) / 100).toString());
    G = parseInt(((G * (100 + percent)) / 100).toString());
    B = parseInt(((B * (100 + percent)) / 100).toString());

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    const RR =
      R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
    const GG =
      G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
    const BB =
      B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

    return "#" + RR + GG + BB;
  }

  /**
   * get extension of file
   * @param filename
   */
  static ext(filename: string): string {
    return Regex.extension.exec(filename)[1];
  }
}
