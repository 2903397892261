<template>
  <vs-tooltip :color="color" :bottom="bottom" :right="right" :left="left">
    <slot />
    <template #tooltip>
      <span class="ff-works ls-1">{{ text }}</span>
    </template>
  </vs-tooltip>
</template>

<script>
export default {
  name: "tooltip-text",
  props: {
    // tooltip text
    text: [String, Number],
    // tooltip color
    color: {
      type: String,
      default: "",
    },
    // right
    right: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped></style>
