/**
 * @info Firebase various handling
 */

import { FirebaseApp, initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Auth, User, UserCredential } from "firebase/auth";
import { Database, getDatabase } from "firebase/database";
import env from "@/config/env";

export default class Firebase {
  public app: FirebaseApp;
  public auth: Auth;
  public db: Database;

  constructor() {
    // configuration

    const firebaseConfig = {
      apiKey: env.firebase.apiKey,
      authDomain: env.firebase.authDomain,
      databaseURL: env.firebase.databaseURL,
      projectId: env.firebase.projectId,
      storageBucket: env.firebase.storageBucket,
      messagingSenderId: env.firebase.messagingSenderId,
      appId: env.firebase.appId,
      measurementId: env.firebase.measurementId,
    };

    // initialize app
    this.app = initializeApp(firebaseConfig);

    // init authentication & realtime db
    this.auth = getAuth();
    this.db = getDatabase();

    /*if (process.env.NODE_ENV !== "production") {
      connectAuthEmulator(this.auth, "http://localhost:9099");
      connectDatabaseEmulator(this.db, "localhost", 9000);
    }*/
  }

  /**
   * get logged in user
   */
  user(): User | null {
    return this.auth.currentUser;
  }

  /**
   * check if user is admin
   * @return Promise<boolean>
   */
  async isAdmin(): Promise<boolean> {
    const tk = await this.auth.currentUser.getIdTokenResult();
    //return !tk.claims.admin;
    return Boolean(tk.claims["admin"]);
  }

  // check if user has permission
  async permission(resource: string, action: string) {
    const tk = await this.auth.currentUser.getIdTokenResult();
    const perm = Boolean(tk.claims.permissions[resource][action]);
    if (perm) return true;
    else throw Error("you don't have permission");
  }

  /**
   * logout user
   */
  async logout(): Promise<void> {
    return this.auth.signOut();
  }

  /**
   * login user with email/password
   * @param email
   * @param password
   */
  async login(email: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(this.auth, email, password);
  }
}
