/**
 * @info simple event bus (basically its vuex but with little simplicity)
 */
import Store from "@/store";
import Global from "@/config/Global";

export default class Events {
  /**
   * type of events
   */
  public static types = {};

  /**
   * push new event
   * @param type
   * @param data
   */
  public static push(type, data) {
    Store.commit("setEvent", {
      type,
      data,
    });
  }

  /**
   * notification
   * @param title
   * @param text
   * @param type - info, success, error, warn
   */
  public static notification(title, text, type = "info") {
    let icon;
    switch (type) {
      case "success":
        icon = "<i class='mdi mdi-check'></i>";
        break;
      case "warn":
        icon = "<i class='mdi mdi-alert-octagon'></i>";
        break;
      case "danger":
        icon = "<i class='mdi mdi-close-octagon'></i>";
        break;
      default:
        icon = "<i class='mdi mdi-information-variant'></i>";
        break;
    }

    const notification = {
      position: "top-center",
      classNotification: "ff-works",
      duration: 6000,
      buttonClose: true,
      border: type,
      title,
      icon,
      text,
    };

    Global.vuetify.notification(notification);
  }

  /**
   * success notification
   * @param title
   * @param description
   */
  public static success(title: string, description = "") {
    this.notification(title, description, "success");
  }

  /**
   * error notification
   * @param description
   */
  public static error(description: string) {
    this.notification("Error", description, "danger");
  }

  /**
   * info notification
   * @param title
   * @param description
   */
  public static info(title: string, description = null) {
    this.notification(title, description, "info");
  }
}
