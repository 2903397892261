





















































import viewGenericWall from "@/views/ViewGenericWall.vue";
import { Component } from "vue-property-decorator";
import Vue from "vue";
import Global from "@/config/Global";
import { ICategory, ISnapshot } from "@/utils/Interfaces";
import ViewCategory from "@/views/ViewCategory.vue";

@Component({
  components: { ViewCategory, viewGenericWall },
})
export default class Categories extends Vue {
  private categories: ISnapshot<ICategory>[] = [];
  private loading: Boolean = true;

  // on create
  created() {
    this.$store.state.sidebar = "category";
    this.fetch();
  }

  // fetch wallpapers
  fetch(start = null) {
    // fetch wallpapers
    Global.database.getLiveListFiltered(
      "content/category",
      start,
      (categories: ISnapshot<ICategory>[]) => {
        this.loading = false;
        this.categories = categories;
      }
    );
  }
}
