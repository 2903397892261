




































































import { Component } from "vue-property-decorator";
import Vue from "vue";
import Global from "@/config/Global";
import { IGeneric, ISnapshot } from "@/utils/Interfaces";
import ViewVideoWall from "@/views/ViewVideoWall.vue";

@Component({
  components: { ViewVideoWall },
})
export default class GenericWalls extends Vue {
  private wallpapers: ISnapshot<IGeneric>[] = [];
  private loading: Boolean = true;
  private total: Number = 0;

  // on create
  created() {
    this.$store.state.sidebar = "video";
    this.fetch();
  }

  // fetch wallpapers
  fetch(start = null) {
    // fetch wallpapers
    Global.database.getLiveListFiltered(
      "content/video",
      start,
      (wallpapers: ISnapshot<IGeneric>[]) => {
        this.loading = false;
        this.wallpapers = wallpapers;
      }
    );
  }
}
