


























































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Global from "@/config/Global";
import { ICategory, ISnapshot, IUploaded } from "@/utils/Interfaces";
import Events from "@/utils/Events";
import Upload from "@/layout/Upload.vue";
import Time from "@/utils/Time";
import TooltipText from "@/components/TooltipText.vue";

@Component({
  components: { TooltipText, Upload },
})
export default class NewGeneric extends Vue {
  private loading = true;
  private tag: string = null;
  private selectedCategory: string;
  private wallType: string = null;
  private coins = 0;
  private tags: string[] = [];
  private categories: ISnapshot<ICategory>[] = [];

  // on component create
  created() {
    this.$store.state.sidebar = "";

    // get all categories
    Global.database
      .getOnce("content/category")
      .then((categories) => {
        // map value to categories snapshot
        Object.keys(categories).forEach((key) => {
          const snapshot: ISnapshot<ICategory> = {
            key,
            value: categories[key],
          };
          this.categories.push(snapshot);
        });
      })
      .catch((err) => {
        console.log(err);
        Events.error("failed to get categories; reload the page");
      })
      .finally(() => {
        this.loading = false;
      });
  }

  get coinCount() {
    return this.coins + " coins";
  }

  // add new tag
  addTag() {
    if (this.tag.length > 0) {
      this.tags.push(this.tag.trim());
      this.tag = null;
    }
  }

  // remove tag
  removeTag(tag) {
    this.tags = this.tags.filter((el) => tag !== el);
  }

  // after image is uploaded
  onUpload(uploaded: IUploaded) {
    // convert category to set
    const categories = {};
    categories[this.selectedCategory] = true;
    // convert tags to set
    const tags = {};
    this.tags.forEach((tag) => {
      tags[tag] = true;
    });

    // generate new key for list
    const updates = {};
    const newWallRef = Global.database.push("content/generic");

    // add new generic wallpaper
    updates["content/generic/" + newWallRef.key] = {
      id: newWallRef.key,
      createdAt: Time.currentUTC(),
      coins: this.coins,
      applies: 0,
      downloads: 0,
      likes: 0,
      views: 0,
      popular: 0,
      premium: this.wallType === "Premium",
      categories,
      src: [uploaded.updated],
      tags,
    };

    // add wallpaper to category
    updates[
      `content/categoryWalls/${this.selectedCategory}/${newWallRef.key}`
    ] = 0;

    // increase wallpaper category count
    updates[`content/category/${this.selectedCategory}/wallpapers`] =
      Global.database.increment(1);

    // if premium increase count
    if (this.wallType === "Premium")
      updates["content/counter/wallpaper/premium/generic"] =
        Global.database.increment(1);

    // increase total count
    updates["content/counter/total/wallpapers"] = Global.database.increment(1);

    // increase generic count
    updates["content/counter/wallpaper/type/generic"] =
      Global.database.increment(1);

    // add to tags & tag counter
    this.tags.forEach((tag) => {
      updates[`content/tags/${tag}/${newWallRef.key}`] = true;
      updates[`content/tags/${tag}/wallpapers`] = Global.database.increment(1);
    });

    // send updates
    Global.database
      .update(updates)
      .then(() => {
        Events.success(
          "Image Uploaded",
          `${uploaded.original} added to database`
        );
      })
      .catch((err) => {
        console.log(err);
        Events.error(`Failed to insert image - ${uploaded.original}`);
      });
  }

  // reload page
  reload() {
    this.$router.go(0);
  }

  // show upload
  get showUpload() {
    return (
      this.wallType !== null &&
      this.tags.length > 0 &&
      this.categories.length > 0
    );
  }
}
