





















































import Global from "@/config/Global";
import Events from "@/utils/Events";

export default {
  name: "ErrorPage",
  props: ["message"],
  data: () => ({
    loading: false,
    firebase: Global.firebase,
  }),
  computed: {
    bg() {
      return {
        "background-color": this.$vuetify.theme.currentTheme.primary,
        "background-image": `url("${require("../assets/bg1.svg")}")`,
        "background-size": "cover",
        height: "100vh",
      };
    },
  },
  methods: {
    retry() {
      // if not admin send for logout request
      if (Global.firebase.auth.currentUser && Global.firebase.isAdmin()) {
        this.firebase
          .logout()
          .then(() => {
            this.$cookies.remove("user");
            this.$store.state.user = false;
            Events.success(
              "Logged Out",
              "successfully logged out, please wait..."
            );
          })
          .catch((err) => {
            Events.error(err.message);
          });

        // else emit retry event
      } else this.$emit("retry");
    },
  },
};
