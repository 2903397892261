






































































import Vue from "vue";
import ErrorPage from "@/pages/ErrorPage.vue";
import Appbar from "@/layout/Appbar.vue";
import Global from "@/config/Global";
import silent from "@/utils/silent";
import Navbar from "@/layout/Sidebar.vue";

export default Vue.extend({
  name: "App",
  components: { Navbar, Appbar, ErrorPage },
  data: () => ({
    error: false,
    errorMsg: null,
    loading: false,
    vsLoading: null,
    firebase: Global.firebase,
  }),
  // created
  async created() {
    this.openLoading();
    await this.checkAuth();

    Global.vuetify = this.$vs;
  },
  // methods
  methods: {
    // open loading
    openLoading() {
      this.loading = true;
      this.error = null;
      this.errorMsg = null;

      this.vsLoading = this.$vs.loading({
        type: "corners",
        color: "#FFF",
        background: this.$vuetify.theme.currentTheme.primary,
        opacity: 1,
        scale: 1.5,
      });
    },

    // check user auth
    async checkAuth() {
      // first check if user cookie is present or not
      if (this.$cookies.isKey("user")) {
        // auth state change listener
        await Global.firebase.auth.onAuthStateChanged(async (user) => {
          // if user present then continue
          if (user) {
            // check if admin or not
            Global.firebase
              .isAdmin()
              .then(async (admin) => {
                if (admin) {
                  this.$store.state.user = true;
                  Global.database.init();
                  await Global.s3.init();
                  await Global.imagekit.init();
                } else {
                  this.error = true;
                  this.errorMsg = "Not an ADMIN user !!!";
                }
              })
              .catch(() => {
                this.$cookies.remove("user");
                this.$router.push({ name: "login" });
              })
              .finally(() => {
                // stop loading
                this.loading = false;
                this.vsLoading.close();
              });
          }
          // else redirect to login
          else await this.$router.push({ name: "login" });
        });
      }
      // user is not logged in
      else {
        this.loading = false;
        this.vsLoading.close();
        this.$store.state.user = false;
        silent(this.$cookies.remove("user"));
        await this.$router.push({ name: "login" });
      }
    },

    //retry
    retry() {
      this.openLoading();
      this.checkAuth();
    },
  },
  // watch
  watch: {
    "$store.state.user": function (user) {
      if (!user) {
        this.openLoading();
        this.checkAuth();
      }
    },
  },
});
