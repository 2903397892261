/**
 * @info s3 service
 */

import aws, { AWSError, S3 as AWS3 } from "aws-sdk";
import Global from "@/config/Global";
import Generate from "@/utils/Generate";
import { ManagedUpload } from "aws-sdk/lib/s3/managed_upload";
import Events from "@/utils/Events";
import Extras from "@/utils/Extras";
import mime from "mime-types";

export default class S3 {
  private s3: AWS3;
  private bucket: string;
  url: string;
  cdn: string;

  /**
   * initialize
   */
  async init() {
    const creds = (await Global.database.getOnce("admin/creds/s3")) as any;
    this.bucket = creds.bucket;
    this.url = `https://${creds.bucket}.${creds.host}`;
    this.cdn = `https://${creds.cdn}`;

    aws.config.update({
      secretAccessKey: creds.secret,
      accessKeyId: creds.key,
    });

    this.s3 = new aws.S3({
      endpoint: new aws.Endpoint(creds.host),
      signatureVersion: "v4",
    });
  }

  /**
   * get signed upload url
   */
  getSignedUrl(): string {
    return this.s3.getSignedUrl("getObject", {
      Bucket: this.bucket,
      Key: `images/${Generate.short()}`,
      Expires: 60 * 10,
    });
  }

  /**
   * upload file
   * @param file
   * @param path
   * @param progress
   */
  async upload(
    file: File,
    path: string,
    progress: (progress: number) => unknown
  ): Promise<ManagedUpload.SendData> {
    return new Promise((resolve, reject) => {
      this.s3
        .upload(
          {
            Key: path,
            Bucket: this.bucket,
            Body: file,
            ACL: "public-read",
            ContentType: mime.lookup(Extras.ext(file.name)),
          },
          (err, result) => {
            // successfully uploaded
            if (!err) resolve(result);
            // error in upload
            else {
              Events.error(err.message);
              reject(err);
            }
          }
        )
        // upload progress
        .on("httpUploadProgress", (value) => {
          progress((value.loaded / value.total) * 100);
        });
    });
  }

  /**
   * upload video
   * @param file
   * @param path
   * @param progress
   */
  async uploadVideo(
    file: File,
    path: string,
    progress: (progress: number) => unknown
  ): Promise<ManagedUpload.SendData> {
    return new Promise((resolve, reject) => {
      this.s3
        .upload(
          {
            Key: path,
            Bucket: this.bucket,
            Body: file,
            ACL: "public-read",
            ContentType: "video/mp4",
          },
          (err, result) => {
            // successfully uploaded
            if (!err) resolve(result);
            // error in upload
            else {
              Events.error(err.message);
              reject(err);
            }
          }
        )
        // upload progress
        .on("httpUploadProgress", (value) => {
          progress((value.loaded / value.total) * 100);
        });
    });
  }

  /**
   * upload file
   * @param data
   * @param path
   * @param progress
   */
  async uploadBuffer(
    data: ArrayBuffer,
    path: string,
    progress: (progress: number) => unknown
  ): Promise<ManagedUpload.SendData> {
    return new Promise((resolve, reject) => {
      this.s3
        .upload(
          {
            Key: path,
            Bucket: this.bucket,
            Body: data,
            ACL: "public-read",
            ContentType: mime.lookup(Extras.ext(path)),
          },
          (err, result) => {
            // successfully uploaded
            if (!err) resolve(result);
            // error in upload
            else {
              Events.error(err.message);
              reject(err);
            }
          }
        )
        // upload progress
        .on("httpUploadProgress", (value) => {
          progress((value.loaded / value.total) * 100);
        });
    });
  }

  /**
   * delete file from s3
   * @param path: complete key path/name
   */
  async deleteFile(path: string): Promise<void> {
    return new Promise((resolve, reject) => {
      // params
      const params = {
        Bucket: this.bucket,
        Key: path,
      };

      // check if file exists
      this.s3
        .headObject(params)
        .promise()
        .then(() => {
          // if yes then delete it
          this.s3.deleteObject(params).promise();
        })
        .then(() => resolve())
        .catch((err: AWSError) => {
          if (err.code === "NotFound") reject(new Error("key/name not found"));
          else reject(err);
        });
    });
  }
}
