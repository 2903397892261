/**
 * @info
 */
import Firebase from "@/services/Firebase";
import Database from "@/services/Database";
import S3 from "@/services/S3";
import Imagekit from "@/services/Imagekit";

export default {
  firebase: new Firebase(),
  database: new Database(),
  s3: new S3(),
  imagekit: new Imagekit(),
  vuetify: null,
};
