/**
 * run functions silently and log the output
 * @param func
 * @public
 */
export default function (func) {
  (async () => {
    try {
      await func;
    } catch (err) {
      console.log(err);
    }
  })();
}
