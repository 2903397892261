/**
 * @info realtime database
 */

import {
  child,
  DatabaseReference,
  get,
  limitToFirst,
  onValue,
  push,
  query,
  ref as Ref,
  set,
  update,
  startAt,
  increment,
} from "firebase/database";

import Global from "@/config/Global";
import { ISnapshot } from "@/utils/Interfaces";

export default class Database {
  ref: DatabaseReference;

  /**
   * initialize database reference
   */
  init() {
    this.ref = Ref(Global.firebase.db);
  }

  /**
   * get push reference
   * @param path
   */
  push(path: string) {
    const listRef = Ref(Global.firebase.db, path);
    return push(listRef);
  }

  /**
   * update/add multiple data at once
   * @param list
   */
  update(list: object) {
    return update(this.ref, list);
  }

  /**
   * increment value
   * @param value
   */
  increment(value: number) {
    return increment(value);
  }

  /**
   * decrement value
   * @param value
   */
  decrement(value: number) {
    return increment(-value);
  }

  /**
   * insert item to list
   * @param path
   * @param value
   */
  async insertToList(path: string, value: any) {
    const listRef = Ref(Global.firebase.db, path);
    const newItemRef = push(listRef);
    return set(newItemRef, value);
  }

  /**
   * get data once at location
   * @param path
   */
  getOnce(path: string): Promise<any> {
    return new Promise((resolve, reject) => {
      get(child(this.ref, path))
        .then((snapshot) => {
          snapshot.exists()
            ? resolve(snapshot.val())
            : reject("No data available");
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  /**
   * get data & watch changes at location
   * @param path
   * @param callback
   */
  getLive<T>(path: string, callback: (value: T) => unknown) {
    onValue(Ref(Global.firebase.db, path), (snapshot) => {
      callback(snapshot.val());
    });
  }

  /**
   * get data & watch changes at location
   * @param path
   * @param callback
   */
  getLiveList<T>(path: string, callback: (value: ISnapshot<T>[]) => unknown) {
    onValue(Ref(Global.firebase.db, path), (parent) => {
      const value = [];
      // iterate over snapshot
      parent.forEach((child) => {
        value.push({
          key: child.key,
          value: child.val(),
        });
      });
      // callback
      callback(value);
    });
  }

  /**
   * get data & watch changes at location
   * @param path
   * @param start
   * @param callback
   */
  getLiveListFiltered<T>(
    path: string,
    start: string,
    callback: (value: ISnapshot<T>[]) => unknown
  ) {
    // create reference with filter
    const ref = startAt
      ? query(Ref(Global.firebase.db, path))
      : query(Ref(Global.firebase.db, path), startAt(start), limitToFirst(30));

    // get value
    onValue(
      ref,
      (parent) => {
        const value = [];
        // iterate over values
        parent.forEach((child) => {
          value.push({
            key: child.key,
            value: child.val(),
          });
        });
        // check if start is present remove first value
        if (start) value.shift();
        // callback
        callback(value);
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
