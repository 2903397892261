








































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { ICategory, IGeneric } from "@/utils/Interfaces";
import Bluebird from "bluebird";
import Global from "@/config/Global";
import TooltipText from "@/components/TooltipText.vue";
import Format from "@/utils/Format";
import Events from "@/utils/Events";
import silent from "@/utils/silent";
import Regex from "@/utils/Regex";

@Component({
  components: { TooltipText },
})
export default class PreviewGeneral extends Vue {
  private loading = false;
  private wallpaper: IGeneric;
  private id: string;
  private categories: Map<string, ICategory> = new Map<string, ICategory>();
  private cats: ICategory[] = [];
  private format = Format;

  // on create
  async created() {
    this.$store.state.sidebar = "";

    this.wallpaper = this.$store.state.generic;
    this.id = this.$route.params.id;
    await this.fetchCategories();
  }

  // get tags
  get tags() {
    return Object.keys(this.wallpaper.tags);
  }

  // src of image
  get src() {
    // get name without extension
    const ext = Regex.extension.exec(this.wallpaper.src[0])[0];
    const name = this.wallpaper.src[0].replace(ext, "");

    return {
      full: `${Global.s3.cdn}/images/generic/${this.wallpaper.src[0]}`,
      thumb: `${Global.s3.cdn}/images/generic/${name}-thumb_small.webp`,
    };
  }

  // fetch categories
  async fetchCategories() {
    const cats = new Map<string, ICategory>();
    await Bluebird.each(Object.keys(this.wallpaper.categories), (item) => {
      return Global.database.getOnce("content/category/" + item).then((cat) => {
        cats.set(item, cat);
      });
    }).then(() => {
      this.categories = cats;
    });
  }

  // delete handling
  deleteWall() {
    this.loading = true;

    Global.firebase
      .permission("generic", "delete")
      .then(() => {
        // generate new key for list
        const updates = {};
        // category key
        const category = Object.keys(this.wallpaper.categories)[0];

        // delete generic
        updates["content/generic/" + this.id] = null;

        // delete category wall
        updates[`content/categoryWalls/${category}/${this.id}`] = null;

        // decrease wallpaper category count
        Object.keys(this.wallpaper.categories).forEach((el) => {
          updates[`content/category/${el}/wallpapers`] =
            Global.database.decrement(1);
        });

        // if premium decrease count
        if (this.wallpaper.premium)
          updates["content/counter/wallpaper/premium/generic"] =
            Global.database.decrement(1);

        // decrease total count
        updates["content/counter/total/wallpapers"] =
          Global.database.decrement(1);

        // decrease generic count
        updates["content/counter/wallpaper/type/generic"] =
          Global.database.decrement(1);

        // remove from tags & tag counter
        this.tags.forEach((tag) => {
          updates[`content/tags/${tag}/${this.id}`] = null;
          updates[`content/tags/${tag}/wallpapers`] =
            Global.database.decrement(1);
        });

        // send updates
        Global.database
          .update(updates)
          .then(() => {
            Events.success("Image Deleted");
            this.$router.push({ name: "generic" });
            this.deleteS3();
          })
          .catch((err) => {
            console.log(err);
            Events.error(`Failed to delete image`);
          })
          .finally(() => {
            this.loading = false;
          });
      })
      .catch((err) => {
        Events.error(err.message);
        this.loading = false;
      });
  }

  // delete from s3
  deleteS3() {
    // get name without extension
    const ext = Regex.extension.exec(this.wallpaper.src[0])[0];
    const name = this.wallpaper.src[0].replace(ext, "");

    // silently delete all
    silent(Global.s3.deleteFile(`images/generic/${this.wallpaper.src[0]}`));
    silent(Global.s3.deleteFile(`images/generic/${name}-thumb_small.webp`));
    silent(Global.s3.deleteFile(`images/generic/${name}-thumb_large.webp`));
    silent(Global.s3.deleteFile(`images/generic/${name}-large.webp`));
  }

  // preview category
  previewCategory(id) {
    this.$router.push({ name: "previewCategory", params: { id } });
  }
}
